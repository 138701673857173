<template>
  <div class="flex flex-col items-center justify-center bg-gray-100 min-h-screen overflow-y-scroll overflow-x-hidden">
    <img :src="icon_url" :width="icon_width">
    <h1 class="font-bold text-2xl mt-2 tracking-tight">
      欢迎登录ME.ink
    </h1>
    <section class="flex justify-center flex-col mt-auto w-full max-w-sm pt-6 login-wrap">
      <div class="wx-wrap">
        <img src=""/>
        <div class="title">ME.ink僖蜜一刻申请获取以下权限</div>
        <div class="sub">获取你的公开信息（昵称、头像等）</div>
        <button
          type="button"
          class="mt-2 w-full p-3 text-center text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg font-semibold wx-login-btn"
          @click="wxLoginH5"
        >
          授权登录
        </button>
        <div class="tips">
          未注册用户授权后自动登录，注册即代表同意 <a @click="goInfo(1)">服务条款</a> 和 <a @click="goInfo(1)">隐私协议</a>
        </div>
      </div>
    </section>
    <section class="flex text-center text-gray-600 text-sm mt-auto mb-4 p-8">僖蜜一刻版权所有.<br>Copyright ©{{ new Date().getFullYear() }}
    </section>
  </div>
</template>
<script>
import { login } from '../../server/login';
import { Toast } from 'vant';
import { getHeadlineInfo } from '../../server/api';
export default {
  data() {
    return {
      icon_url : require('../../assets/logo.png'),
      icon_width : 60
    };
  },
  created() {
    console.log(this.$route);
    var { query } = this.$route;
    if(query.token) {
      this.$store.commit('auth/login', query.token);
      Toast.success('登录成功');
      this.getProfile(query.token)
    }
  },
  methods: {
    // H5点击登录
    async wxLoginH5() {
      var query = this.$route.query;
      const params = {
        is_api: true,
        app_id: process.env.VUE_APP_H5_APPID,
        target_url: query.url ? decodeURIComponent(query.url) : window.location.href
      }
      const { code , redirectUrl} = await login(params);
      if(code == 200){
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          window.location.href = params.target_url;
        }
      }
    },
    // 跳转到协议
    goInfo(type) {
      if(type == 1) {
        this.$router.push('/service');
      }else {
        this.$router.push('/privacy');
      }
    },
    // 获取headle
    async getProfile(token) {
      try {
        const { code , data } = await getHeadlineInfo({
          token: token
        })
        if (code == 200) {
          this.$router.push({
            path: `/u/${data.handle}`
          })
        } else {
          Toast.err('获取用户信息失败，请重试');
        }
      } catch (err) {
        console.log('Error getting profile');
        console.log('失败',err);
      }
    },
  }
}
</script>
<style>
@import "./index.css";
.NeutronLogo {
  width: 180px;
}
.Header__Link {
  border: 1px solid #868686;
  border-radius: 20px;
  padding: 2px 6px;
  margin: 0px 20px;
}
.login-wrap .el-tabs__nav-scroll{
  width: 50%;
  margin: auto;
}
#wxLogin .title {
  display: none !important;
}
.wx-wrap {
  height: 350px;
  margin: 0px 32px;
}
.wx-wrap .title {
  color: #0A0A0A;
  font-size: 16px;
}
.wx-wrap .sub {
  color: #9F9F9F;
  font-size: 14px;
}
.wx-login-btn {
  margin-top: 30px;
}
.tips {
  font-size: 12px;
  margin-top: 12px;
}
.tips a {
  text-decoration: underline;
  font-weight: 500;
}
* {
  outline: none !important;
}
</style>
