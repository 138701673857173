<template>
  <div
    id="user-profile-view"
    class="relative flex bg-gray-100 justify-center w-full sl-bg"
  >
    <div class="menu flex max-w-sm">
      <div @click="goHome" class="left-icon">
        <van-icon v-if="is_oneself != 1 " name="apps-o" size="24" color="white"/>
        <template v-if="is_oneself == 1">
          <div v-if="goMini">
            <wx-open-launch-weapp
                id="launch-btn"
                username="gh_82bbea7fc555"
                path="pages/index/index.html"
                style="display: inline-block;width: 100%"
            >
                <script type="text/wxtag-template">
                    <style>
                      .btn {
                        font-size: 12px;
                        line-height: 30px;
                        color: white;
                      }
                    </style>
                    <div class="btn">设置</div>
                </script>
            </wx-open-launch-weapp>
          </div>
          <div v-else class="setting">设置</div>
          <!-- <van-icon v-else name="setting-o" size="24" color="white"/> -->
        </template>
      </div>
      <div class="right-icon">
        <div>
          <!-- <van-icon class="add" name="plus" size="24" color="white" @click="release"/> -->
          <div v-if="goMini" @click="release">
            <wx-open-launch-weapp
                id="launch-btn"
                username="gh_82bbea7fc555"
                path="pages/add/index.html"
                style="display: inline-block;width: 100%"
            >
                <script type="text/wxtag-template">
                    <style>
                      .add-btn {
                        font-size: 12px;
                        line-height: 30px;
                        color: white;
                        width: 30px;
                        text-align: center;
                      }
                    </style>
                    <div class="add-btn">发布</div>
                </script>
            </wx-open-launch-weapp>
          </div>
          <div v-else>
            <!-- <van-icon class="add" name="plus" size="24" color="white" @click="release"/> -->
            <div class="add-btn">发布</div>
          </div>
        </div>
        <div>
          <van-icon name="share-o" size="24" color="white" @click="share"/>
        </div>
      </div>
    </div>
    
    <section class="flex flex-col p-2 pt-8 pb-8 items-center text-center max-w-sm w-full">
      <component :is="'style'" v-if="theme">
        .sl-headline {
        color: {{ theme.colors.text.primary }};
        }

        .sl-subtitle {
        opacity: .85;
        color: {{ theme.colors.text.primary }};
        }

        .sl-bg {
        background: {{ theme.colors.fill.primary }};
        }

        .sl-item {
        background: {{ theme.colors.fill.secondary }};
        }

        .sl-label {
        color: {{ theme.colors.text.secondary }};
        }

        .sl-link-subtitle {
        opacity: .85;
        color: {{ theme.colors.text.secondary }};
        }
      </component>

      <component :is="'style'" v-if="(theme ? theme.customCss : false)">
        {{ theme.customCss || null }}
      </component>

      <component :is="'style'" v-if="profile.customCss">
        {{ profile.customCss || null }}
      </component>

      <!-- 头像 -->
      <img
        v-if="profile.imageUrl || user.avatarUrl || user.emailHash"
        class="nc-avatar mb-2"
        :src="profile.imageUrl || user.avatarUrl || 'https://www.gravatar.com/avatar/' + user.emailHash"
        alt="profile image"
        onerror="this.src='https://www.gravatar.com/avatar'"
      >
      <!-- 账号名 -->
      <h1 class="text-black font-semibold text-2xl sl-headline">
        {{ profile.headline || user.name }}
      </h1>
      <!-- 标题 -->
      <h3 class="text-gray-600 mb-4 sl-subtitle">
        {{ profile.subtitle }}
      </h3>
      <!-- 关注按钮 -->
      <button
        type="button"
        id="save-and-add-link-btn"
        class="inline-flex p-3 text-sm text-white text-center bg-red-500 rounded-lg font-semibold w-full justify-center align-center mb-4"
        :class="isFollow?'followBg':''"
        v-if="is_oneself==0 || !isWx"
        @click="follow"
      >
        {{ isFollow ? '取消关注' : '关注 + ' }}
      </button>

      <!-- 社交媒体账号处理 -->
      <div class="w-full flex justify-center">
        <ul class="flex overflow-auto overflow-x-auto ul-wrap">
          <li v-for="(item,index) in mediaList" :key="index" class="img-wrap">
            <a :href="(item.label=='视频号'||item.label=='公众号') ? 'javascript:void(0)' : item.url" @click="openMedia(item)">
            <!-- <a @click="openMedia(item)"> -->
              <div class="flex justify-center">
                <img :src="item.icon" class="media-img">
              </div>
              <p class="logo-name">{{item.label}}</p>
            </a>
          </li>
        </ul>
      </div>
      <div class="tab w-full flex mb-2 mt-1 tab-label">
        <div @click="changeActiveName(0)">链接</div>
        <div @click="changeActiveName(1)">关注</div>
        <div @click="changeActiveName(2)">收藏</div>
        <div class="van-tabs__line" :style="`transform: translateX(${100 * index}%);transition-duration: 0.3s;`"></div>
      </div>

      <div class="addTips" @click="release">
        <div v-if="goMini">
          <wx-open-launch-weapp
              id="launch-btn"
              username="gh_82bbea7fc555"
              path="pages/add/index.html"
              style="display: inline-block;width: 100%"
          >
              <script type="text/wxtag-template">
                  <style>
                    .mini-btn {
                      height: 30px;
                      line-height: 30px;
                      color: white;
                      text-align: center;
                    }
                  </style>
                  <div class="mini-btn">我要发布</div>
              </script>
          </wx-open-launch-weapp>
        </div>
        <div v-else>
          我要发布
        </div>
      </div>
      <!-- 我的链接页面 -->
      <div v-if="index==0" class="w-full">
        <a
          v-for="link in links"
          :id="'sl-item-'+link.id"
          :key="link.id"
          class="w-full"
          target="_blank"
          :href="link.url"
        >
          <div
            class="rounded shadow bg-white p-4 w-full font-medium nc-link sl-item  flex items-center justify-between links_wrap"
            :style="link.customCss"
          >
            <div class="links-content" :class="is_oneself==0 ? 'link-left-wrap':''">
              <img v-if="link.image" :src="link.image" />
              <div class="link">
                <div><span class="font-medium text-gray-900 sl-label">{{ link.label }}</span></div>
                <div><span v-if="link.subtitle" class="text-sm text-gray-700 sl-link-subtitle mt-1">{{ link.subtitle }}</span></div>
              </div>
            </div>
            <div v-if="is_oneself==0">
              <van-icon :name="link.isCollection=='1' ? 'star':'star-o'" :color="link.isCollection=='1' ? '#F3CE4B':''" size="24" @click.prevent="likeLink(link)"/>
              <br/>
              <van-popover
                v-model="link.showPopover"
                placement="bottom-end"
                theme="dark"
                trigger="click"
                :actions="actions"
                class="add-link"
                @select="e=>onSelectPopver(e,link)"
              >
                <template #reference>
                  <van-icon name="more-o" size="24" @click.prevent="openPopover"/>
                </template>
              </van-popover>
            </div>
            <div v-if="is_oneself==1">
              <!-- 自己主页 -->
              <van-icon name="edit" size="24" @click.prevent="editLink(link)"/>
            </div>
          </div>
        </a>
      </div>

      <div v-if="index==1" class="w-full">
        <a>
          <div class="follow-wrap nc-link sl-item" v-for="(follow,index) in follows" :key="index" @click="goHomepage(follow)">
            <div class="follow-user-info">
              <img
                v-if="follow.profile.image_url"
                class="follow-img"
                :src="follow.profile.image_url"
                alt="profile image"
                onerror="this.src=default_avatar_url"
              >
              <div class="follow-user-name">
                <div>{{follow.profile.headline}}</div>
                <div class="follow_subtitle">{{follow.profile.subtitle}}</div>
              </div>
            </div>
            <button
              type="button"
              id="save-and-add-link-btn"
              class="inline-flex follow-btn p-1 text-sm text-white text-center bg-red-500 rounded-lg font-semibold justify-center align-center"
              :class="follow.is_follow=='1' ? 'followBg':''"
              @click.stop="cancelFollow(follow)"
            >
              {{follow.is_follow=='1' ? '已关注':'关注' }}  
            </button>
          </div>
        </a>
      </div>

      <div v-if="index==2" class="w-full">
        <a
          v-for="collection in collections"
          :id="'sl-item-'+collection.id"
          :key="collection.id"
          class="w-full"
          target="_blank"
          :href="collection.link.url"
        >
          <div
            class="rounded shadow bg-white p-4 w-full font-medium nc-link sl-item  flex items-center justify-center justify-between collections_wrap"
            :style="collection.customCss"
          >
            <div class="collection-left-wrap">
              <img v-if="collection.link.image" :src="collection.link.image" />
              <div>
                <div class="font-medium text-gray-900 sl-label">{{ collection.link.label }}</div>
                <div v-if="collection.link.subtitle"  class="text-sm text-gray-700 sl-link-subtitle mt-1">{{ collection.link.subtitle }}</div>
              </div>
            </div>
            <van-icon :name="collection.is_collection=='1' ? 'star':'star-o'" :color="collection.is_collection=='1' ? '#F3CE4B':''" size="24" @click.prevent="cancelCollection(collection)"/>
          </div>
        </a>
      </div>

      <div v-if="loaded && profile.showWatermark" id="sl-watermark">
        <p v-if="theme" :style="`color:${theme.colors.text.primary}`" class="mt-4 text-sm">
          <a
            v-if="free_signup"
            class="text-indigo-600 hover:underline text-sm"
            :href="$i18n.path('create-account')"
            target="_blank"
          >
            链接你的所有想象 🔗
          </a>
        </p>
        <p v-else style="color:rgba(0,0,0,1);" class="mt-4 text-sm">
          <a
            v-if="free_signup"
            class="text-indigo-600 hover:underline text-sm"
            @click="goPropaganda"
            target="_blank"
          >
            链接你的所有想象 🔗
          </a>
        </p>
      </div>

      <div id="custom-html" v-html="profile.customHtml"/>
      <div v-if="theme" id="theme-html" v-html="theme.customHtml"/>
    </section>

    <van-share-sheet
      v-model="showShare"
      title=""
      :options="options"
      @select="onSelect"
    >
    </van-share-sheet>

    <van-overlay :show="show" @click="openShare">
      <div class="wrapper" @click.stop>
        <van-image
          width="200"
          height="200"
          class="share_img"
          :src="share_img_url"
        />
        <div class="img-title">长按二维码保存到相册</div>
      </div>
    </van-overlay>

    <!-- 转发按钮---本人主页 -->
    <LinkPopups v-if="linkPopupShow" :show="linkPopupShow" :pendingLink="pendingLink" title="编辑链接" :closePopup="closeLinkPopups" :type="type" :getProfile="getProfile"/>
    <!-- 转发按钮---他人主页 -->
    <OtherLinkPopups v-if="otherLinkPopupShow" :show="otherLinkPopupShow" :pendingLink="pendingLink" title="转发链接" :closePopup="closeOtherLinkPopups" :type="type" :getProfile="getProfile"/>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog , Toast } from 'vant';
import LinkPopups from './component/LinkPopups.vue';
import OtherLinkPopups from './component/OtherLinkPopups.vue';
import store from '../../store';
import { detectDeviceType, isWeChat , getCookie } from '@/utils/utils';
import { getProfileInfo , cancleFollow , followInfo , collectionInfo , cancelCollection , IsExpire , quickTurn , getPreview , getShareInfo , getSignature } from '../../server/api';


export default Vue.extend({
  name: 'UserProfileView',
  components: {
    LinkPopups,
    OtherLinkPopups
  },
  props: {
    profileHandle: {
      type: String,
    },
    authenticated: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    profileData: {
      type: Object,
      default: null
    }
  },
  watch: {
    '$route'(to,form) {
      if(to.path != form.path){
        if (this.authenticated) {
          this.getAuthenticatedProfile();
        } else {
          if(!this.preview) {
            this.getProfile();
          } else {
            // do nothing
            this.getAuthenticatedProfile();
            this.profile.customCss = this.profileData.customCss;
            this.profile.customHtml = this.profileData.customHtml;
            this.theme = this.profileData;
            this.loaded = true;
          }
        }
      }
    },
  },
  data() {
    return {
      default_url : require('../../assets/setting.png'),
      loaded: false,
      profile: {
        id: null,
        customHtml: null,
        customCss: null,
        imageUrl: null,
        headline: null,
        subtitle: null,
        showWatermark: true,
        metadata: {
          privacyMode: false
        }
      },
      user: {
        name: null,
        emailHash: null,
        avatarUrl: null,
        id: ''
      },
      theme: null,
      links: null,
      hostname: process.env.HOSTNAME,
      api_url: process.env.API_URL,
      app_name: process.env.APP_NAME,
      icon_url: process.env.ICON_URL,
      free_signup: process.env.FREE_SIGNUP,
      default_avatar_url: process.env.DEFAULT_AVATAR_URL,
      mediaList:[
      ],
      isFollow:false,
      follows:[
      ],
      collections:[
      ],
      token:getCookie('auth_token'),
      loadings: null , // 正在请求数据
      options: [
        [
          { name: '复制链接', icon: 'link' },
          { name: '二维码', icon: 'qrcode' },
        ],
      ],
      showShare: false,
      show: false,
      share_img_url:'',
      share_url:'',
      activeName:'',
      index: 0,
      is_oneself: null , //是否是自己，1代表是自己，0代表不是，不是自己的时候跳转到自己的主页
      profile_url: '' , //个人主页链接
      isWx: null,
      popupshow: false,
      actions: [{ text: '快转' }, { text: '转发' }],
      showPopover:false,
      close : true ,
      linkPopupShow: false,
      otherLinkPopupShow: false,
      title:'',
      pendingLink: {
        id: "",
        label: "",
        subtitle: "",
        url: "",
        imgurl: null,
      },
      releaseLink: {
        id: "",
        label: "",
        subtitle: "",
        url: "",
        imgurl: null,
      },
      type:'1', // type : 1 -  转发 , 2 - 编辑自己的链接
      releaseShow: false,
      media_img_url: null,
      media_title: null,
      mediaShow : false,
      media_url : '',
      goMini : false,
      mini: {
        '#小程序://快团团':'gh_b5403cc2567a',
        '#小程序://拼多多':'gh_0e7477744313',
        '#小程序://接龙':'gh_38274c71b09c',
        '#小程序://有赞微商城':'gh_b4fc4f3f1ab1',
        '#小程序://快手短视频':'gh_414546d6bf5a',
        '#小程序://知乎':'gh_bc33767b4df6',
        '#小程序://微博':'gh_5f1a249e0ced',
        '#小程序://小红书App':'gh_52be748ce5b7',
      },
      miniType : ['#小程序://快团团','#小程序://拼多多','#小程序://接龙','#小程序://有赞微商城','#小程序://快手短视频','#小程序://知乎','#小程序://微博','#小程序://小红书App']
    };
  },
  created(){
    let url = location.href.split('#')[0]
    let isIOS = function () {
        var isIphone = navigator.userAgent.includes('iPhone')
        var isIpad = navigator.userAgent.includes('iPad')
        return isIphone || isIpad
      }
    if (isIOS()) {
      url = store.state.auth.url
    }
    // getSignature({target_url:window.location.href}).then(res=>{
    getSignature({target_url:url}).then(res=>{
      if(res.code == 200){
        if (window.wx) {
          window.wx.config({
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            // appId: 'wxe7c683eff6caffa1', // 必填，公众号的唯一标识
            appId: res.data.appId,
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature,// 必填，签名，见附录1
            jsApiList: ['chooseImage', 'previewImage' , 'startRecord','stopRecord'],
            openTagList: ['wx-open-launch-weapp'],
          })
          window.wx.ready(function(res){
            console.log('ready',res);
          })
          window.wx.error(function(res){
            console.log('res',res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        }
      }
    })

    
    var query = this.$route.query;
    if(this.token){
      this.goMini = true;
      this.goMiniUrl = '"pages/index/index';
    }
    if(query.token){
      this.$store.commit('auth/login', query.token);
      Toast.success('登录成功');
      this.$router.push({ query: {} });
      this.token = query.token;
      this.goMini = true;
      this.goMiniUrl = '"pages/index/index';
    }
    if (this.authenticated) {
      this.getAuthenticatedProfile();
    } else {
      if(!this.preview) {
        this.getProfile();
      } else {
        // do nothing
        this.getAuthenticatedProfile();
        this.profile.customCss = this.profileData.customCss;
        this.profile.customHtml = this.profileData.customHtml;
        this.theme = this.profileData;
        this.loaded = true;
      }
    }

  },
  mounted() {
    // this.isWx = isWeChat();
    this.$root.$on('refreshUserProfileView', () => {
      // this.$fetch();
      this.getHeadInfo();
    });
  },

  methods: {
    // 开始录音
    startRecord() {
      window.wx.startRecord({
        success: function(res) {
          console.log("成功", res);
        },
        fail: function(res) {
          console.log("失败", res);
        },
      });

      setTimeout(()=>{
        window.wx.ready(function() {
          window.wx.stopRecord({
            success: function(res) {
              // 获取本地语音id
              const localId = res.localId;
              console.log('res====',res);
            },
            fail: function(res) {
              console.log("失败", res);
            },
          });
        });
        window.wx.fail(function(err) {
          console.log("失败", err);
        });
      },5000)
    },
    async getHeadInfo(){
      if (this.authenticated) {
        await this.getAuthenticatedProfile();
      } else {
        if(!this.preview) {
          await this.getProfile();
        } else {
          // do nothing
          await this.getAuthenticatedProfile();
          this.profile.customCss = this.profileData.customCss;
          this.profile.customHtml = this.profileData.customHtml;
          this.theme = this.profileData;
          this.loaded = true;
        }
      }
    },
    async tokenIsExpire(){
      // token是否过期
      await IsExpire({
        token: store.state.auth.token || this.token
      })
    },
    getPath(path,findStr,num) {
      var idx = path.indexOf(findStr);
      var count = 1;
      while(idx >= 0 && count < num){
          idx = path.indexOf(findStr, idx+1);
          count++;
      }    
      if(idx < 0){
          return '';
      }
      return path.substring( idx+1 , path.length);
    },
    getUserName(path){
      for(let i in this.mini){
        if(path.indexOf(i)>-1){
          return this.mini[i]
        }
      }
    },
    open(url) {
      window.location.href = url;
    },
    copyMedia(){
      this.$copyText(this.media_url).then(
        function(e) {
          Toast('复制成功');
        },
        function(e) {
          alert("复制失败!");
        }
      );
    },
    openMediaPop(){
      this.mediaShow = !this.mediaShow;
    },
    // 打开媒体链接openMedia
    openMedia(item){
      if(item.label=='视频号'||item.label=='公众号'){
        this.media_img_url = item.image;
        this.media_url = item.url;
        this.media_title = item.label;
        this.mediaShow = true;
      }else{
        window.location.href = item.url;
      }
    },
    // 点击修改链接（自己主页）
    editLink(link){
      if(this.token){
        this.pendingLink = link;
        this.type = '2';
        this.linkPopupShow = true;
        this.title = '编辑链接'
      }else{
        this.login();
      }
    },
    // 关闭closeLinkPopups(自己主页)
    async closeLinkPopups(){
      if (this.authenticated) {
        await this.getAuthenticatedProfile();
      } else {
        if(!this.preview) {
          await this.getProfile();
        } else {
          // do nothing
          await this.getAuthenticatedProfile();
          this.profile.customCss = this.profileData.customCss;
          this.profile.customHtml = this.profileData.customHtml;
          this.theme = this.profileData;
          this.loaded = true;
        }
      }
      this.linkPopupShow = false;
    },
    // 关闭closeLinkPopups(自己主页)
    async closeOtherLinkPopups(){
      if (this.authenticated) {
        await this.getAuthenticatedProfile();
      } else {
        if(!this.preview) {
          await this.getProfile();
        } else {
          // do nothing
          await this.getAuthenticatedProfile();
          this.profile.customCss = this.profileData.customCss;
          this.profile.customHtml = this.profileData.customHtml;
          this.theme = this.profileData;
          this.loaded = true;
        }
      }
      this.otherLinkPopupShow = false;
    },
    // 关闭快速发布
    async closeReleasePopups(){
      if (this.authenticated) {
        await this.getAuthenticatedProfile();
      } else {
        if(!this.preview) {
          await this.getProfile();
        } else {
          // do nothing
          await this.getAuthenticatedProfile();
          this.profile.customCss = this.profileData.customCss;
          this.profile.customHtml = this.profileData.customHtml;
          this.theme = this.profileData;
          this.loaded = true;
        }
      }
      this.releaseShow = false;
      this.releaseLink = {
        id: "",
        label: "",
        subtitle: "",
        url: "",
        imgurl: null,
      }
    },
    openPopover() {
    },
    // 他人主页的链接操作按钮
    onSelectPopver(action,link) {
      if(this.token){
        if(action.text == '快转'){
          this.turn(link);
        }else{
          this.pendingLink = link;
          this.otherLinkPopupShow = true;
        }
      }else{
        this.login();
      }
    },
    // 添加发布
    release() {
      if(this.token){
        this.tokenIsExpire();
      }else{
        this.login();
      }
    },
    // 跳转到宣传页
    goPropaganda(){
      window.location.href = 'https://' + process.env.HOSTNAME
    },
    changeActiveName(index){
      this.index = index;
    },
    goHomepage(follow){
      // this.$router.push(follow.profile.handle);
      window.location.href = `https://${process.env.VUE_APP_HOSTNAME}/#/u/${follow.profile.handle}`
    },
    // 快转
    async turn(link) {
      try {
        const { code, message, msg } = await quickTurn({
          token: store.state.auth.token || this.token,
          id: link.id
        })
        if(code == 200){
          Toast.success('已发布');
        } else {
          Toast(message || msg)
        }
      } catch (err) {
        console.log('Error getting profile');
        console.log('失败',err);
      }
    },
    async getProfile() {
      try {
        this.loadings = true;
        const { code , data, message, msg } = await getProfileInfo(`/profile/${this.profileHandle}`,{
          token: store.state.auth.token || this.token,
        })
        if(code == 200){
          this.is_oneself = data.is_oneself;
          this.profile_url = data.profile_url;
          this.profile = data.profile;
          this.isFollow = data.profile.isFollow;
          this.links = data.links;
          this.mediaList = data.medias;
          this.follows = data.follows;
          this.collections = data.collections;
          this.user = data.user;
          this.theme = data.theme || null;
          this.loadings = false;
          this.activeName = '1';
        } else {
          Toast(message || msg)
        }
        

        // fail-safe
        if (!this.profile.metadata) {
          this.profile.metadata = {
            privacyMode: false
          };
        }
      } catch (err) {
        console.log('Error getting profile');
        console.log('失败',err);
      }
      this.loaded = true;
    },
    async getTheme() {
      this.profile.customCss = this.theme?.customCss;
      this.profile.customHtml = this.theme?.customHtml;
    },

    async getAuthenticatedProfile() {
      try {
        this.loadings = true;
        const { code , data, message, msg } = await getPreview({
          token: store.state.auth.token || this.token,
        })
        if(code == 200){
          this.is_oneself = data.is_oneself;
          this.profile_url = data.profile_url;
          this.profile = data.profile;
          this.isFollow = data.profile.isFollow;
          this.links = data.links;
          this.mediaList = data.medias;
          this.follows = data.follows;
          this.collections = data.collections;
          this.user = data.user;
          this.theme = data.theme || null;
          this.loadings = false;
          this.activeName = '1';
        } else {
          Toast(message || msg)
        }
      } catch (err) {
        console.log('Error getting profile');
        console.log(err);
      }

      this.loaded = true;
    },
    async getShare(id) {
      var source
      if (detectDeviceType()) {
        if(isWeChat()) {
          source = 1
        } else {
          source = 2
        }
      } else {
        source = 3
      }
      const { code , data, message, msg } = await getShareInfo({
        token: store.state.auth.token || this.token,
        profile_id: id,
        source: source
      })
      if(code==200){
        console.log('qwer');
        this.share_img_url = data.img_url;
        this.share_url = data.share_url;
        this.showShare = true;
      } else {
        Toast(message || msg)
      }
    },
    // 点击主页的关注和取消关注
    async follow(){
      if(this.token){
        if(this.isFollow){
          Dialog.confirm({
            title: '取消关注',
            confirmButtonText: '确定',
            cancelButtonText: '关闭'
          })
            .then(async () => {
              this.isFollow = false;
              const { code , message, msg } = await cancleFollow({
                token: store.state.auth.token || this.token,
                profile_id : this.profile.id
              })
              if(code == 200){
                this.isFollow = false;
              } else {
                Toast(message || msg)
              }
            })
            .catch(() => {
            });
        }else{
          const { code, message, msg } = await followInfo({
            token: store.state.auth.token || this.token,
            profile_id : this.profile.id
          })
          if(code == 200){
            this.isFollow = true;
          } else {
            Toast(message || msg)
          }
        }
      }else{
        this.login();
      }
    },

    // 导航栏收藏 和 取消收藏
    async likeLink(link){
      console.log('点击');
      if(this.token){
        var newLinks = JSON.parse(JSON.stringify(this.links));
        //如果链接已收藏则取消收藏,否则收藏
        if(link.isCollection==1){
          // 取消收藏
          const { code, message, msg } = await cancelCollection({
            token: store.state.auth.token || this.token,
            link_id : link.id
          })
          if(code == 200){
            newLinks.map((item)=>{
              if(item.id == link.id){
                item.isCollection = item.isCollection==1 ? 0 : 1;
              }
            })
            this.links = newLinks;
          } else {
            Toast(message || msg)
          }
        }else{
          // 收藏
          try {
            const { code, message, msg } = await collectionInfo({
              link_id : link.id,
              token: store.state.auth.token || this.token,
            })
            if(code == 200 ){
              newLinks.map((item)=>{
                if(item.id == link.id){
                  item.isCollection = item.isCollection==1 ? 0 : 1;
                }
              })
              this.links = newLinks;
            } else {
              Toast(message || msg)
            }
          } catch (error) { 
            console.log('error',error);
          }
        }
      }else{
        this.login();
      }
    },

    // 导航栏取消关注达人
    async cancelFollow(follow){
      if(this.token){
        // 关注达人 is_follow：1 已关注 0 未关注
        var newFollows = JSON.parse(JSON.stringify(this.follows));
        if(follow.is_follow == 1) {
          //去取消关注
          const { code, message, msg } = await cancleFollow({
            token: store.state.auth.token || this.token,
            profile_id : follow.profile_id
          })
          if(code == 200){
            newFollows.map(item => {
              if(item.id == follow.id){
                // 取消关注
                // 是自己的主页则删除，不是自己的主页不删，改变样式
                item.is_follow = item.is_follow==1 ? 0 : 1;
              }
            })
            this.follows = newFollows;
          } else {
            Toast(message || msg)
          }
        }else {
          // 关注（关注不涉及到删除这个数据）
          const { code, massage, msg } = await followInfo({
            token: store.state.auth.token || this.token,
            profile_id : follow.profile_id
          })
          if(code == 200){
            newFollows.map(item => {
              if(item.id == follow.id){
                item.is_follow = item.is_follow==1 ? 0 : 1;
              }
            })
          } else {
            Toast(massage || msg)
          }
          this.follows = newFollows;
        }
      }else{
        this.login();
      }
    },
    // 导航栏取消收藏
    async cancelCollection(collection){
      if(this.token){
        var newCollections = JSON.parse(JSON.stringify(this.collections));
        if(collection.is_collection == 1){
          // 去取消收藏
          const { code, message, msg } = await cancelCollection({
            token: store.state.auth.token || this.token,
            link_id : collection.link_id
          })
          if(code == 200){
            newCollections.map((item,index)=>{
              if(item.link_id == collection.link_id){
                // 取消关注
                item.is_collection = item.is_collection == 1 ? 0 : 1;
              }
            })
            // if(this.is_oneself != 1){
              this.collections = newCollections;
            // }
          } else {
            Toast(message || msg)
          }
        }else{
          // 可以收藏
          // 收藏
          try {
            const { code, message, msg } = await collectionInfo({
              token: store.state.auth.token || this.token,
              link_id : collection.link_id
            })
            if(code == 200 ){
              newCollections.map((item)=>{
                if(item.link_id == collection.link_id){
                  item.is_collection = item.is_collection==1 ? 0 : 1;
                }
              })
              this.collections = newCollections;
            } else {
              Toast(message || msg)
            }
          } catch (error) { 
            console.log('error', error)
          }
        }
      }else{
        this.login();
      }
    },
    // 分享按钮
    async share () {
      this.getShare(this.profile.id)
    },
    openShare(){
      this.show = !this.show;
    },
    // 点击具体某个分享按钮
    onSelect(option) {
      var _this = this;
      if(option.name == '复制链接'){
        let oInput = document.createElement("input");
        //这边为链接地址this.liveLink='www.baidu.com'
        oInput.value = this.share_url;
        document.body.appendChild(oInput);
        oInput.select();
        console.log(oInput.value);
        document.execCommand("Copy");
        oInput.remove();
        _this.showShare = false;
        Toast('链接复制成功，快去分享给朋友吧～');
      }else if(option.name == '二维码'){
        _this.showShare = false;
        this.show = true;
      }
    },
    // 点击回首页
    goHome() {
      // 先判断是否已经登录
      if(this.token){
        this.tokenIsExpire();
        if(this.is_oneself == 1){
          console.log('当前是自己主页，跳转到设置页面')
          // 回到设置页面
          // this.$router.push('/dashboard');
          // 跳转到小程序页面
          this.goMini = true;
        }else{
          console.log('当前不是自己主页，跳转到自己主页')
          console.log('this.profile_url',this.profile_url);
          // 回主页
          this.$router.push('/u/'+this.profile_url)
        }
      }else {
        // 登录（调用登录的接口）
        this.login();
      }
    },
    // 登录
    async login() {
      this.$router.push('/login?url=' + encodeURIComponent(window.location.href));
    }
  }
});
</script>
<style>
@import "./index.css";
.nc-avatar {
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  object-fit: cover;
}

.nc-link {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding: 16px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 6px;
  cursor: pointer;
  transition: .15s ease-in-out;
  box-sizing: border-box;
}

.nc-link:hover {
  transform: scale(1.02);
}

.nc-link:active {
  transform: scale(1);
}

body {
  overflow-x: hidden;
}

.menu {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  /* left: 8px; */
  /* right: 8px; */
  width: 100%;
  z-index: 90;
  /* padding: 10px 10px 0px 10px; */
  background: transparent !important;
}
.menu .left-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, 0.3);
  border-radius: 50%;
  cursor: pointer;
}
.menu .right-icon {
  width: 70px;
  display: flex;
  justify-content: space-between;
}
.right-icon>div {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, 0.3);
  border-radius: 50%;
  cursor: pointer;
}
.menu .right-icon .add {
  margin: auto;
}

.wrapper {
  margin: auto;
  background: white;
  padding: 4px;
  border-radius: 4px;
  height: 240px;
  width: 240px;
  position: fixed;
  top: 50%;
  margin-top: -120px;
  left: 50%;
  margin-left: -120px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items:center ;
}

.wrapper1 {
  margin: auto;
  background: white;
  padding: 4px;
  border-radius: 4px;
  height: 270px;
  width: 240px;
  position: fixed;
  top: 50%;
  margin-top: -120px;
  left: 50%;
  margin-left: -120px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items:center ;
}

.img-wrap {
  display: flex;
  justify-content: center;
  margin: 0px 8px;
  flex: none;
}
.media-img {
  width: 32px;
  height: 32px;
}

.tab {
  position: relative;
  height: 44px;
  font-weight: bold;
}

.tab div{
  flex: 1;
  line-height: 41px;
}

.van-sticky--fixed {
  padding: 0px 1.5rem;
  max-width: 24rem;
  margin: auto;
}

.van-overlay {
  max-width: 24rem;
  margin: auto;
  z-index: 999;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
.van-popup {
  max-width: 22rem;
}

.van-share-sheet {
  max-width: 24rem;
  margin: auto;
  left:50%;
  transform:translate(-50%,0%);
}

.van-tabs__line {
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1;
    width: 33.333%;
    height: 3px;
    background-color: #ee0a24;
    border-radius: 3px;
}
.followBg {
  background: white !important;
  border: 1px solid rgb(240, 235, 235) !important;
  color: black !important;
}
.follow-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.follow-user-info {
  flex: 1;
  display: flex;
  align-items: center;
}
.follow-user-name {
  flex: 1;
  object-fit: cover;
  margin:0 8px;
  text-align: left !important;
}
.follow-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.follow-btn {
  width: 80px;
}
.follow_subtitle {
  font-size: 12px;
}
.collection-sub {
  margin-right: 4px;
}
.links_wrap , .collections_wrap{
  display: flex;
  text-align: left;
  margin-bottom: 6px;
}
.links-content {
  display: flex;
}
.links-content img{
  width : 50px;
  height: 100%;
  margin-right: 10px;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
  border-radius: 0px;
}
/* 滚动条 */
.ul-wrap::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
  border-radius: 0px;
}

/* 滚动条两端按钮 */
.ul-wrap::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

/* 外层轨道 */
.ul-wrap::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* 内层轨道，它会覆盖外层轨道的样式 */
.ul-wrap::-webkit-scrollbar-track-piece {
  width: 4px;
  margin: 0 -2px 0;
}

/* 滑块 */
.ul-wrap::-webkit-scrollbar-thumb {
  background-color: transparent;
  min-height: 0px;
  min-width: 0px;
  border-radius: 5px;
}

/* 纵向滑块悬浮 */
.ul-wrap::-webkit-scrollbar-thumb:vertical:hover {
  background-color: transparent;
}

/* 横向滑块悬浮 */
.ul-wrap::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #333;
}

.link-left-wrap {
  max-width: 80% !important;
}
.collection-left-wrap {
  max-width: 85% !important;
  display: flex;
}
.collection-left-wrap img{
  width : 50px;
  height: 100%;
  margin-right: 10px;
}

.addTips {
  width: 100%;
  height: 30px;
  background: rgba(240, 82, 82);
  margin: 5px 0px;
  color: white;
  border-radius: 4px;
}

.logo-name {
  font-size: 14px;
  color: black;
}

.add-link {
  margin-top: 6px;
}
button {
  border: 0px;
}
a {
  color: black;
}
.setting {
  font-size: 12px;
  line-height: 30px;
}
.add-btn {
  font-size: 12px;
  line-height: 30px;
  color: white;
  width: 30px;
  text-align: center;
}

</style>
