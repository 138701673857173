import Vue from 'vue';
import Router from 'vue-router';
import U from '../pages/u/_.vue';
import login from '../pages/login/login.vue';
import privacy from '../pages/info/privacy.vue';
import service from '../pages/info/service.vue';
import { getCookie } from '@/utils/utils';
import router from '@/router/index';
import { getHeadlineInfo } from '@/server/api';

//vue全局使用Router
Vue.use(Router)

const routes = [
    {
      path: '/u/*',
      component: U,
      meta: {
          title: ''
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: privacy,
      meta: {
        title: '隐私协议 - ME.ink'
      }
    },
    {
      path: '/service',
      name: 'service',
      component: service,
      meta: {
        title: '服务协议 - ME.ink'
      }
    },
    {
      path: '/login',
      component : login,
      meta: {
        title : '登录 - ME.ink'
      }
    }
]
let routers = new Router({
    mode: 'hash',
    base: '/',
    routes
})

routers.beforeEach(async (to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if(to.path == '/'){
      // 首页，如果有token的话
      if(getCookie('auth_token')){
        const { code , data } = await getHeadlineInfo({
          token: getCookie('auth_token')
        })
        if(code == 200) {
          router.push({
            path: `/u/${data.handle}`
          })
        } else {
          router.push('/login');
        }
      }else{
        router.push('/login');
      }
    }
    next()
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default routers